import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate để điều hướng
import './BaoCao.css';

const BaoCao = () => {
  const [currentReport, setCurrentReport] = useState('revenue');
  const navigate = useNavigate(); // Khởi tạo useNavigate

  const handleSwitchReport = (reportType) => {
    setCurrentReport(reportType);
  };

  const handleGoBack = () => {
    navigate('/admin-home'); // Chuyển hướng về trang AdminHome
  };

  return (
    <div className="bao-cao-container">
      <header className="bao-cao-header">
        <h2>Báo cáo</h2>
        <div className="report-menu">
          <button onClick={() => handleSwitchReport('revenue')}>Báo cáo doanh thu</button>
          <button onClick={() => handleSwitchReport('sales')}>Báo cáo bán hàng</button>
        </div>
        <button className="back-button" onClick={handleGoBack}>Quay lại</button> {/* Nút Quay lại */}
      </header>

      {currentReport === 'revenue' ? (
        <div className="bao-cao-content">
          <h3>BÁO CÁO DOANH THU</h3>
          <table className="report-table">
            <thead>
              <tr>
                <th>STT</th>
                <th>Khoản mục</th>
                <th>Giá trị</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Tiền bán hàng</td>
                <td>85,445,213 VND</td>
              </tr>
              {/* Thêm các dòng khác */}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="bao-cao-content">
          <h3>BÁO CÁO BÁN HÀNG</h3>
          <table className="report-table">
            <thead>
              <tr>
                <th>Ngày</th>
                <th>Tổng</th>
                <th>Tiền hàng</th>
                <th>Tiền từ dịch vụ</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1/11/2021</td>
                <td>3,888,788 VND</td>
                <td>3,688,788 VND</td>
                <td>200,000 VND</td>
              </tr>
              {/* Thêm các dòng khác */}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default BaoCao;
