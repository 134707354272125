import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PhaCheHome.css';

const PhaCheHome = () => {
  const navigate = useNavigate(); // Sử dụng useNavigate để điều hướng

  const handleQuanLyKhoClick = () => {
    navigate('/quanly-kho'); // Điều hướng đến trang Quản lý Kho
  };

  const handleBackToHomeClick = () => {
    navigate('/'); // Điều hướng đến trang chủ http://localhost:3000
  };

  return (
    <div className="phache-home">
      <header className="phache-header">
        <div className="header-left">
          <i className="fas fa-bars"></i>
          <span>DS món chế biến</span>
        </div>
        <div className="header-center">
          <span onClick={handleQuanLyKhoClick} style={{ cursor: 'pointer' }}>Quản lý Kho</span>
        </div>
        <div className="header-right">
          <i className="fas fa-wifi"></i>
          <i className="fas fa-question-circle"></i>
          <span>Võ Minh Tuấn</span>
          <i className="fas fa-user-circle"></i>
        </div>
      </header>

      <main className="phache-main">
        {/* Nội dung trang */}
        <div className="table-card">
          <h2>Bàn 012 - Tầng 1</h2>
          <p>Order: 1.1</p>
          <p>Phục vụ: Trần Quang Minh</p>
          <ul>
            <li>Black Coffee (3 ly)</li>
            <li>Trà Xanh Dự định (2 ly)</li>
            <li>Fat White (1 ly)</li>
            <li>Trà Thạch Đào (3 ly)</li>
            <li>Phô Mai Trà Xanh (2 phần)</li>
            <li>Tiramisu (1 ly)</li>
          </ul>
        </div>
        <div className="table-card">
          <h2>Bàn 008 - Tầng 2</h2>
          <p>Order: 1.2</p>
          <p>Phục vụ: Trần Quang Minh</p>
          <ul>
            <li>Cafe Latte (1 ly)</li>
            <li>Phô Mai Cà Phê (1 phần)</li>
          </ul>
        </div>
        <div className="table-card">
          <h2>Bàn 004 - Tầng 1</h2>
          <p>Order: 1.3</p>
          <p>Phục vụ: Trần Quang Minh</p>
          <ul>
            <li>Trà Thạch Đào (1 ly)</li>
            <li>Black Coffee (2 ly)</li>
            <li>Cappuccino (2 ly)</li>
            <li>Irish Coffee (1 ly)</li>
            <li>Mousse Cacao (2 phần)</li>
            <li>Phô Mai Caramel (1 phần)</li>
          </ul>
        </div>
      </main>

      <footer className="phache-footer">
        <div className="table-footer">
          <span>Bàn 012 - Tầng 1</span>
          <span>16°</span>
        </div>
        <div className="table-footer">
          <span>Bàn 008 - Tầng 2</span>
          <span>10°</span>
        </div>
        <div className="table-footer">
          <span>Bàn 004 - Tầng 1</span>
          <span>1°</span>
        </div>
      </footer>

      {/* Nút Quay lại ở góc phải */}
      <button className="back-button" onClick={handleBackToHomeClick}>
        Quay lại
      </button>
    </div>
  );
};

export default PhaCheHome;
