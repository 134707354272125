import React from 'react';
import { useNavigate } from 'react-router-dom';

function ThongBaoTraMon() {
  const navigate = useNavigate();

  const handlePayment = () => {
    navigate('/thanh-toan-thanh-cong');
  };

  return (
    <div className="thong-bao-tra-mon">
      <h1>MH Hiển Thị Thông Báo Trả Món Thành Công</h1>
      <button onClick={handlePayment}>Thanh Toán</button>
    </div>
  );
}

export default ThongBaoTraMon;
