import React from 'react';
import './PhieuXuatKho.css';  // Tạo file CSS riêng cho trang này

const PhieuXuatKho = () => {
  return (
    <div className="phieu-xuat-kho">
      <h2>Phiếu xuất kho</h2>
      <form>
        <label>Mã PX</label>
        <input type="text" value="PX0017" readOnly />
        <label>Người lập phiếu</label>
        <input type="text" value="Võ Minh Tuấn" readOnly />
        <label>Ngày xuất</label>
        <input type="date" value="2021-11-30" />
        <label>Xuất kho</label>
        <select>
          <option>Xuất kho bán hàng</option>
          <option>Hủy nguyên vật liệu</option>
        </select>
        <label>Thời gian</label>
        <input type="date" value="2021-11-30" /> đến <input type="date" value="2021-11-30" />
        <button type="button">Chọn món đã bán</button>
        <table>
          <thead>
            <tr>
              <th>Mã NVL</th>
              <th>Tên NVL</th>
              <th>Đơn vị tính</th>
              <th>Số lượng</th>
              <th>Đơn giá</th>
              <th>Thành tiền</th>
            </tr>
          </thead>
          <tbody>
            {/* Các dòng dữ liệu */}
          </tbody>
        </table>
        <button type="button">Thêm dòng</button>
        <button type="button">Xóa dòng</button>
        <div className="form-footer">
          <button type="submit">Lưu</button>
          <button type="button">Hủy bỏ</button>
          <button type="button">Giúp</button>
        </div>
      </form>
    </div>
  );
};

export default PhieuXuatKho;
