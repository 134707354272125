import React, { useEffect, useState } from 'react';
import 'tailwindcss/tailwind.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWifi, faQuestionCircle, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './AdminHome.css';

const AdminHome = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  // Hàm lấy danh sách sản phẩm từ API
  const fetchProducts = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/products');
      setProducts(response.data);
    } catch (error) {
      console.error('Lỗi khi lấy danh sách sản phẩm:', error);
    }
  };

  // Điều hướng đến trang sửa sản phẩm
  const handleEditProduct = (productId) => {
    navigate(`/sua-san-pham/${productId}`);
  };

  // Hàm xóa sản phẩm
  const handleDeleteProduct = async (productId) => {
    if (window.confirm("Bạn có chắc chắn muốn xóa sản phẩm này không?")) {
      try {
        await axios.delete(`http://localhost:5000/api/products/${productId}`);
        alert("Sản phẩm đã được xóa.");
        fetchProducts(); // Cập nhật lại danh sách sản phẩm sau khi xóa
      } catch (error) {
        console.error('Lỗi khi xóa sản phẩm:', error);
        alert("Xóa sản phẩm thất bại.");
      }
    }
  };

  // Điều hướng đến các trang quản lý
  const handleGoToQuanLySanPham = () => {
    navigate('/quan-ly-san-pham');
  };

  const handleGoToQuanLyNguoiDung = () => {
    navigate('/quan-ly-nguoi-dung');
  };

  const handleGoToBaoCao = () => {
    navigate('/bao-cao');
  };

  const handleGoToKhuyenMai = () => {
    navigate('/khuyen-mai');
  };

  const handleBackToHomeClick = () => {
    navigate('/');
  };

  const handleLogoutClick = () => {
    navigate('/');
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div className="admin-container">
      <header className="admin-header">
        <div className="header-left">
          <div className="logo">Admin Panel</div>
          <div className="nav">
            <button onClick={handleGoToQuanLySanPham}>Thực đơn</button>
            <button onClick={handleGoToQuanLyNguoiDung}>Nhân viên</button>
            <button onClick={handleGoToBaoCao}>Báo cáo</button>
            <button onClick={handleGoToKhuyenMai}>Khuyến mãi</button>
          </div>
        </div>
        <div className="header-right">
          <FontAwesomeIcon icon={faWifi} className="text-xl" />
          <FontAwesomeIcon icon={faQuestionCircle} className="text-xl" />
          <button onClick={handleLogoutClick} className="icon-button">
            <FontAwesomeIcon icon={faPowerOff} className="text-xl" />
          </button>
          <span>(Admin) Trịnh Ngọc Anh Tuấn</span>
        </div>
      </header>

      <main className="admin-content">
        <div className="menu-actions">
          <button onClick={handleGoToQuanLySanPham}>Thêm</button>
          <button onClick={() => alert('Chọn sản phẩm để sửa.')}>Sửa</button>
          <button onClick={() => alert('Chọn sản phẩm để xóa.')}>Xóa</button>
        </div>

        <table className="menu-table">
          <thead>
            <tr>
              <th>Loại món</th>
              <th>Mã món</th>
              <th>Tên món</th>
              <th>Nhóm thực đơn</th>
              <th>Đơn vị tính</th>
              <th>Giá</th>
              <th>Hành động</th>
            </tr>
          </thead>
          <tbody>
            {products.length > 0 ? (
              products.map((product) => (
                <tr key={product._id}>
                  <td>{product.category}</td>
                  <td>{product.code}</td>
                  <td>{product.name}</td>
                  <td>{product.nhomThucDon || '-'}</td>
                  <td>{product.donViTinh || '-'}</td>
                  <td>{product.price.toLocaleString('vi-VN')} VND</td>
                  <td>
                    <button onClick={() => handleEditProduct(product._id)}>Sửa</button>
                    <button onClick={() => handleDeleteProduct(product._id)}>Xóa</button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center">Không có sản phẩm nào.</td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="pagination">
          <span>Trang 1/7</span>
          <button className="page-button">◄</button>
          <button className="page-button">►</button>
        </div>
      </main>

      <button className="back-button" onClick={handleBackToHomeClick}>
        Quay lại
      </button>
    </div>
  );
};

export default AdminHome;
