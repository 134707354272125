import React from 'react';

function ThanhToanThanhCong() {
  return (
    <div className="thanh-toan-thanh-cong">
      <h1>MH Yêu Cầu Thanh Toán Thành Công</h1>
    </div>
  );
}

export default ThanhToanThanhCong;
