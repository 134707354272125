import React, { useState } from 'react';
import './LoginPage.css';
import logo from '../assets/images/logo.jpg'; // Đảm bảo rằng bạn có ảnh logo trong thư mục này
import { useNavigate } from 'react-router-dom';  // Sử dụng để điều hướng

function LoginPage() {
  const [role, setRole] = useState('');          // Trạng thái cho vai trò
  const [username, setUsername] = useState('');  // Trạng thái cho tên đăng nhập
  const [password, setPassword] = useState('');  // Trạng thái cho mật khẩu
  const navigate = useNavigate();                // Sử dụng useNavigate để điều hướng

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log("Tên đăng nhập:", username);
    console.log("Mật khẩu:", password);
    console.log("Vai trò đã chọn:", role);
    
    // Giả lập xác thực người dùng, có thể thay bằng API gọi đến backend
    if (username === 'admin' && password === 'admin123' && role === 'Admin') {
      navigate('/admin-home'); // Điều hướng đến trang admin
    } else if (username === 'thungan' && password === 'thungan123' && role === 'Thu ngân') {
      navigate('/cashier-home'); // Điều hướng đến trang thu ngân
    } else if (username === 'phucvu' && password === 'phucvu123' && role === 'Phục vụ') {
      navigate('/phuc-vu'); // Điều hướng đến trang phục vụ
    } else if (username === 'phache' && password === 'phache123' && role === 'Pha chế') {
      navigate('/pha-che'); // Điều hướng đến trang pha chế
    } else {
      alert('Sai thông tin đăng nhập hoặc vai trò. Vui lòng thử lại!');
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleLogin}>
        <div className="logo">
          <img src={logo} alt="Nopita Coffee Logo" />
        </div>
        <h1>NOPITA COFFEE</h1>
        <p>SINCE 1993</p>

        <div className="input-group">
          <label htmlFor="username">Tên đăng nhập</label>
          <input 
            type="text" 
            id="username" 
            placeholder="Nhập tên đăng nhập" 
            required 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} // Lưu tên đăng nhập
          />
        </div>

        <div className="input-group">
          <label htmlFor="password">Mật khẩu</label>
          <input 
            type="password" 
            id="password" 
            placeholder="Nhập mật khẩu" 
            required 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} // Lưu mật khẩu
          />
        </div>

        <div className="role-group">
          <label>
            <input 
              type="radio" 
              value="Phục vụ" 
              checked={role === 'Phục vụ'} 
              onChange={(e) => setRole(e.target.value)} 
            />
            Phục vụ
          </label>
          <label>
            <input 
              type="radio" 
              value="Thu ngân" 
              checked={role === 'Thu ngân'} 
              onChange={(e) => setRole(e.target.value)} 
            />
            Thu ngân
          </label>
          <label>
            <input 
              type="radio" 
              value="Admin" 
              checked={role === 'Admin'} 
              onChange={(e) => setRole(e.target.value)} 
            />
            Admin
          </label>
          <label>
            <input 
              type="radio" 
              value="Pha chế" 
              checked={role === 'Pha chế'} 
              onChange={(e) => setRole(e.target.value)} 
            />
            Pha chế
          </label>
        </div>

        <button type="submit" className="login-button">ĐĂNG NHẬP</button>
      </form>
    </div>
  );
}

export default LoginPage;
