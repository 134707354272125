import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './SuaSanPham.css';

const SuaSanPham = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({
    loaiMon: '',
    name: '',
    code: '',
    price: 0,
    nhomThucDon: '',
    donViTinh: '',
    ingredients: [],
    image: '',
  });
  const [loading, setLoading] = useState(true);
  const [previewImage, setPreviewImage] = useState('');

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`http://localhost:5000/api/products/${id}`);
        if (response.data) {
          setProduct(response.data);
          
          // Kiểm tra xem có trường ảnh và tạo URL đầy đủ
          if (response.data.image) {
            const imageUrl = response.data.image.startsWith('http')
              ? response.data.image
              : `http://localhost:5000/${response.data.image}`;
            setPreviewImage(imageUrl);
          }
        } else {
          console.log('Sản phẩm không tồn tại hoặc không có dữ liệu.');
        }
      } catch (error) {
        console.error('Lỗi khi lấy thông tin sản phẩm:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  if (loading) {
    return <div>Đang tải...</div>;
  }

  const handleUpdateProduct = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('loaiMon', product.loaiMon);
    formData.append('name', product.name);
    formData.append('code', product.code);
    formData.append('price', product.price);
    formData.append('nhomThucDon', product.nhomThucDon);
    formData.append('donViTinh', product.donViTinh);
    formData.append('ingredients', JSON.stringify(product.ingredients));

    if (product.image && typeof product.image !== 'string') {
      formData.append('image', product.image);
    }

    try {
      const response = await axios.put(`http://localhost:5000/api/products/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Cập nhật sản phẩm thành công:', response.data);
      navigate('/admin-home');
    } catch (error) {
      console.error('Lỗi khi cập nhật sản phẩm:', error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProduct({ ...product, image: file });
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleAddIngredient = () => {
    setProduct({
      ...product,
      ingredients: [...product.ingredients, { name: '', quantity: '', unit: '' }],
    });
  };

  const handleIngredientChange = (index, field, value) => {
    const updatedIngredients = [...product.ingredients];
    updatedIngredients[index][field] = value || '';
    setProduct({ ...product, ingredients: updatedIngredients });
  };

  const handleDeleteIngredient = (index) => {
    const updatedIngredients = product.ingredients.filter((_, i) => i !== index);
    setProduct({ ...product, ingredients: updatedIngredients });
  };

  return (
    <div className="sua-san-pham-container">
      <h1>Chỉnh sửa sản phẩm</h1>
      <form onSubmit={handleUpdateProduct}>
        <div className="form-group">
          <label>Loại:</label>
          <div>
            <input type="radio" name="loai" value="Đồ uống" checked={product.loaiMon === "Đồ uống"} onChange={() => setProduct({ ...product, loaiMon: "Đồ uống" })} /> Đồ uống
            <input type="radio" name="loai" value="Món ăn" checked={product.loaiMon === "Món ăn"} onChange={() => setProduct({ ...product, loaiMon: "Món ăn" })} /> Món ăn
          </div>
        </div>
        
        <div className="form-group">
          <label>Tên món:</label>
          <input type="text" value={product.name || ''} onChange={(e) => setProduct({ ...product, name: e.target.value })} />
        </div>

        <div className="form-group">
          <label>Mã món:</label>
          <input type="text" value={product.code || ''} onChange={(e) => setProduct({ ...product, code: e.target.value })} />
        </div>

        <div className="form-group">
          <label>Giá bán:</label>
          <input type="number" value={product.price || ''} onChange={(e) => setProduct({ ...product, price: e.target.value })} />
        </div>

        <div className="form-group">
          <label>Nhóm thực đơn:</label>
          <input type="text" value={product.nhomThucDon || ''} onChange={(e) => setProduct({ ...product, nhomThucDon: e.target.value })} />
        </div>

        <div className="form-group">
          <label>Đơn vị tính:</label>
          <input type="text" value={product.donViTinh || ''} onChange={(e) => setProduct({ ...product, donViTinh: e.target.value })} />
        </div>

        <div className="image-container">
          <label>Ảnh đại diện:</label>
          {previewImage ? (
            <img src={previewImage} alt="Current product" />
          ) : (
            <p>Không có ảnh hiện tại</p>
          )}
          <input type="file" onChange={handleImageChange} />
          <p>Chọn các ảnh có định dạng (.jpg, .jpeg, .png, .gif)</p>
        </div>

        <div className="ingredient-section">
          <h2>Định lượng nguyên vật liệu</h2>
          {product.ingredients.map((ingredient, index) => (
            <div key={index} className="ingredient-row">
              <input type="text" placeholder="Tên nguyên liệu" value={ingredient.name || ''} onChange={(e) => handleIngredientChange(index, 'name', e.target.value)} />
              <input type="text" placeholder="Số lượng" value={ingredient.quantity || ''} onChange={(e) => handleIngredientChange(index, 'quantity', e.target.value)} />
              <input type="text" placeholder="Đơn vị" value={ingredient.unit || ''} onChange={(e) => handleIngredientChange(index, 'unit', e.target.value)} />
              <button type="button" onClick={() => handleDeleteIngredient(index)} className="delete-button">X</button>
            </div>
          ))}
          <button type="button" onClick={handleAddIngredient} className="ingredient-add-button">Thêm nguyên liệu</button>
        </div>

        <div className="form-actions">
          <button type="submit" className="button">Lưu</button>
          <button type="button" onClick={() => navigate('/admin-home')} className="button cancel-button">Hủy bỏ</button>
        </div>
      </form>
    </div>
  );
};

export default SuaSanPham;
