import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function KhuyenMaiList() {
    const [promotions, setPromotions] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('http://localhost:3000/api/promotions')
            .then(response => {
                setPromotions(response.data);
            })
            .catch(error => console.error("Không thể lấy dữ liệu khuyến mãi:", error));
    }, []);

    const handleEdit = (id) => {
        // Chuyển hướng đến trang EditKhuyenMai cùng với ID của khuyến mãi
        navigate(`/edit-khuyenmai/${id}`);
    };

    return (
        <div>
            <h2>Danh sách Khuyến mãi</h2>
            <table>
                <thead>
                    <tr>
                        <th>Mã KM</th>
                        <th>Tên KM</th>
                        <th>Ngày Bắt Đầu</th>
                        <th>Ngày Kết Thúc</th>
                        <th>Giá Trị (%)</th>
                        <th>Hành động</th>
                    </tr>
                </thead>
                <tbody>
                    {promotions.map((promo) => (
                        <tr key={promo._id}>
                            <td>{promo.maKM}</td>
                            <td>{promo.tenKM}</td>
                            <td>{promo.ngayBatDau}</td>
                            <td>{promo.ngayKetThuc}</td>
                            <td>{promo.giaTri}</td>
                            <td>
                                <button onClick={() => handleEdit(promo._id)}>Sửa</button>
                                {/* Thêm nút "Xóa" ở đây nếu cần */}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default KhuyenMaiList;
