import React from 'react';
import 'tailwindcss/tailwind.css'; // Ensure Tailwind CSS is available
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWifi, faQuestionCircle, faPowerOff, faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../../../tailwind.css';
import './TraMon.css'; // Import CSS

function TraMon() {
  const navigate = useNavigate(); // Khởi tạo navigate

  // Hàm xử lý khi bấm nút
  const handleLogoutClick = () => {
    navigate('/phuc-vu'); // Điều hướng đến trang /phuc-vu
  };

  return (
    <div className="bg-custom">
      <div className="bg-header text-header flex justify-between items-center p-4">
        <div className="flex items-center space-x-4">
          <i className="fas fa-bars text-2xl"></i>
          <div className="flex items-center space-x-2">
            <i className="fas fa-clipboard-list text-xl"></i>
            <span className="text-lg">Order</span>
          </div>
          <div className="flex items-center space-x-2">
            <i className="fas fa-utensils text-xl"></i>
            <span className="text-lg">Trả món</span>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <i className="fas fa-wifi text-xl"></i>
          <i className="fas fa-question-circle text-xl"></i>
          <FontAwesomeIcon icon={faWifi} className="text-xl" />
          <FontAwesomeIcon icon={faQuestionCircle} className="text-xl" />

          {/* Nút FontAwesomeIcon */}
          <button onClick={handleLogoutClick} className="icon-button">
            <FontAwesomeIcon icon={faPowerOff} className="text-xl" />
          </button>
          <div className="flex items-center space-x-2">
            <span>Tuấn</span>
            <FontAwesomeIcon icon={faUser} className="text-xl" />
          </div>
        </div>
      </div>

      {/* Các phần khác trong giao diện */}
      <div className="grid-layout p-4">
        <div className="left-section overflow-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-header text-header">
                <th className="p-2 border border-custom">Bàn order</th>
                <th className="p-2 border border-custom">Số lượng</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-selected text-header">
                <td className="p-2 border border-custom">Tầng 1 - 012</td>
                <td className="p-2 border border-custom flex justify-between items-center">
                  9
                  <i className="fas fa-check text-xl"></i>
                </td>
              </tr>
              <tr className="bg-white">
                <td className="p-2 border border-custom">Tầng 1 - 004</td>
                <td className="p-2 border border-custom">8</td>
              </tr>
              <tr className="bg-white">
                <td className="p-2 border border-custom">Tầng 2 - 008</td>
                <td className="p-2 border border-custom">3</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="right-section section">
          <div className="bg-white p-4 border border-custom">
            <h2 className="text-lg font-bold mb-4">1.1 - Bàn 012/Tầng 1</h2>
            <table className="w-full border-collapse2">
              <tbody>
                <tr className="bg-white">
                  <td className="p-2 border border-custom">Black Coffee</td>
                  <td className="p-2 border border-custom">3</td>
                  <td className="p-2 border border-custom text-center">
                    <i className="fas fa-check text-xl text-green-600"></i>
                  </td>
                </tr>
                <tr className="bg-white">
                  <td className="p-2 border border-custom">Trà Xanh Đậu Đỏ</td>
                  <td className="p-2 border border-custom">1</td>
                  <td className="p-2 border border-custom text-center">
                    <i className="fas fa-check text-xl text-green-600"></i>
                  </td>
                </tr>
                <tr className="bg-white">
                  <td className="p-2 border border-custom">Flat white</td>
                  <td className="p-2 border border-custom">1</td>
                  <td className="p-2 border border-custom text-center">
                    <i className="fas fa-check text-xl text-green-600"></i>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="flex justify-end mt-4">
              <button className="bg-white border border-custom p-2 flex items-center space-x-2">
                <i className="fas fa-check text-xl text-green-600"></i>
                <span>Trả hết</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <button className="add-button">
        <i className="fas fa-plus"></i>
      </button>
    </div>
  );
}

export default TraMon;
