// CashierPage.js
import React from 'react';

const CashierPage = () => {
  return (
    <div>
      <h1>Thu ngân</h1>
      <div>
        <h2>Chọn bàn</h2>
        <select>
          <option>Bàn 1</option>
          <option>Bàn 2</option>
        </select>
      </div>
      <div>
        <h3>Danh sách món đã gọi</h3>
        {/* Hiển thị danh sách các món */}
      </div>
      <div>
        <h3>Chương trình khuyến mãi</h3>
        <input type="text" placeholder="Nhập mã khuyến mãi..." />
      </div>
      <div>
        <h2>Tổng tiền: 0 đ</h2>
      </div>
    </div>
  );
};

export default CashierPage;
