// OrderPage.js
import React from 'react';

const OrderPage = () => {
  return (
    <div>
      <h1>Gọi món</h1>
      <div>
        <h2>Chọn số bàn</h2>
        <select>
          <option>Bàn 1</option>
          <option>Bàn 2</option>
          {/* Thêm các bàn khác */}
        </select>
      </div>
      <div>
        <h2>Chọn món</h2>
        <input type="text" placeholder="Tìm kiếm món..." />
        <div>
          <h3>Coffee</h3>
          {/* Hiển thị các món trong menu Coffee */}
        </div>
        <div>
          <h3>Trà</h3>
          {/* Hiển thị các món trong menu Trà */}
        </div>
        {/* Các mục menu khác */}
      </div>
    </div>
  );
};

export default OrderPage;
