// ThuNganHome.js
import React from 'react';
import 'tailwindcss/tailwind.css'; // Ensure Tailwind CSS is available
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faDollarSign, faBalanceScale, faWifi, faQuestionCircle, faPowerOff, faUser , faClock, faPen, faCalculator, faEllipsisH, faUsers } from '@fortawesome/free-solid-svg-icons';
import '../../../tailwind.css';
import './ThuNganHome.css';

const ThuNganHome = () => {
    return (
        <div className="bg-[#d2b49c]">
            <header className="bg-[#5b2a1d] text-white flex items-center justify-between p-2">
                <div className="flex items-center space-x-4">
                    <FontAwesomeIcon icon={faBars} className="text-xl" />
                    <button className="flex items-center space-x-2 bg-[#d2b49c] text-[#5b2a1d] px-2 py-1 rounded focus:outline-none">
                        <FontAwesomeIcon icon={faDollarSign} />
                        <span>Thanh toán</span>
                    </button>
                    <button className="flex items-center space-x-2 focus:outline-none">
                        <FontAwesomeIcon icon={faBalanceScale} />
                        <span>Thu chi</span>
                    </button>
                </div>
                <div className="flex items-center space-x-4">
                    <FontAwesomeIcon icon={faWifi} className="text-xl" />
                    <FontAwesomeIcon icon={faQuestionCircle} className="text-xl" />
                    <FontAwesomeIcon icon={faPowerOff} className="text-xl" />
                    <span>Tuấn</span>
                    <FontAwesomeIcon icon={faUser } className="text-xl" />
                </div>
            </header>
            <main className="p-4">
                <div className="flex items-center space-x-2 mb-4">
                    <button className="bg-[#5b2a1d] text-white px-4 py-2 rounded focus:outline-none">Chờ thanh toán (1)</button>
                    <button className="border border-gray-300 px-4 py-2 rounded focus:outline-none">Mang về (0)</button>
                </div>
                <div className="bg-[#5b2a1d] text-white p-2 rounded-t-lg w-64">
                    <div className="flex justify-between items-center">
                        <span>1.1 - Tầng 1/012</span>
                        <FontAwesomeIcon icon={faUsers} />
                    </div>
                </div>
                <div className="bg-[#a67c68] text-white p-4 rounded-b-lg w-64">
                    <div className="flex justify-between items-center mb-2">
                        <div className="flex items-center space-x-2">
                            <FontAwesomeIcon icon={faClock} />
                            <span>16'</span>
                        </div>
                        <div className="text-2xl">286.000 VND</div>
                    </div>
                    <div className="flex space-x-2">
                        <FontAwesomeIcon icon={faPen} />
                        <FontAwesomeIcon icon={faCalculator} />
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </div>
                </div>
            </main>
        </div>
    );
};

export default ThuNganHome;