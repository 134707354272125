// AddKhuyenMai.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './AddKhuyenMai.css';

const AddKhuyenMai = () => {
  const navigate = useNavigate();
  const [promotionCode, setPromotionCode] = useState('');
  const [promotionName, setPromotionName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [promotionValue, setPromotionValue] = useState('');
  const [errors, setErrors] = useState({});

  // Kiểm tra hợp lệ
  const validateForm = () => {
    const newErrors = {};
    if (!promotionCode) newErrors.promotionCode = 'Mã khuyến mãi không được để trống';
    if (!promotionName) newErrors.promotionName = 'Tên khuyến mãi không được để trống';
    if (!startDate) newErrors.startDate = 'Ngày bắt đầu không được để trống';
    if (!endDate) newErrors.endDate = 'Ngày kết thúc không được để trống';
    if (!promotionValue || isNaN(promotionValue) || promotionValue < 0 || promotionValue > 100) {
      newErrors.promotionValue = 'Giá trị khuyến mãi phải là số từ 0 đến 100';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Xử lý lưu khuyến mãi
  const handleSavePromotion = async () => {
    if (validateForm()) {
      try {
        const response = await axios.post('http://localhost:5000/api/promotions', {
          promotionCode,
          promotionName,
          startDate,
          endDate,
          promotionValue
        });
        if (response.status === 200) {
          alert('Khuyến mãi đã được lưu!');
          navigate('/khuyen-mai');
        }
      } catch (error) {
        console.error("Lỗi khi lưu khuyến mãi:", error);
        alert("Đã có lỗi xảy ra khi lưu khuyến mãi");
      }
    } else {
      alert('Vui lòng kiểm tra và nhập đầy đủ thông tin!');
    }
  };

  return (
    <div className="add-khuyen-mai-container">
      <header className="add-khuyen-mai-header">
        <h2>Thêm Khuyến mãi</h2>
        <button onClick={() => navigate('/khuyen-mai')}>Quay lại</button>
      </header>

      <main className="add-khuyen-mai-content">
        <form>
          <div className="form-group">
            <label>Mã Khuyến mãi:</label>
            <input
              type="text"
              value={promotionCode}
              onChange={(e) => setPromotionCode(e.target.value)}
              placeholder="Nhập mã khuyến mãi"
            />
            {errors.promotionCode && <span className="error">{errors.promotionCode}</span>}
          </div>

          <div className="form-group">
            <label>Tên Khuyến mãi:</label>
            <input
              type="text"
              value={promotionName}
              onChange={(e) => setPromotionName(e.target.value)}
              placeholder="Nhập tên khuyến mãi"
            />
            {errors.promotionName && <span className="error">{errors.promotionName}</span>}
          </div>

          <div className="form-group">
            <label>Ngày bắt đầu:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            {errors.startDate && <span className="error">{errors.startDate}</span>}
          </div>

          <div className="form-group">
            <label>Ngày kết thúc:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            {errors.endDate && <span className="error">{errors.endDate}</span>}
          </div>

          <div className="form-group">
            <label>Giá trị Khuyến mãi (%):</label>
            <input
              type="text"
              value={promotionValue}
              onChange={(e) => setPromotionValue(e.target.value)}
              placeholder="Nhập giá trị khuyến mãi"
            />
            {errors.promotionValue && <span className="error">{errors.promotionValue}</span>}
          </div>

          <div className="form-actions">
            <button type="button" onClick={handleSavePromotion}>Lưu</button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default AddKhuyenMai;
