// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import OrderPage from './components/OrderPage';
import ReturnPage from './components/ReturnPage';
import CashierPage from './components/CashierPage';
import PhucVuHome from './components/staffPages/PhucVu/PhucVuHome';
import GhiOrder from './components/staffPages/PhucVu/GhiOrder';
import OrderThanhCong from './components/staffPages/PhucVu/OrderThanhCong';
import TraMon from './components/staffPages/PhucVu/TraMon';
import ThongBaoTraMon from './components/staffPages/PhucVu/ThongBaoTraMon';
import ThanhToanThanhCong from './components/staffPages/PhucVu/ThanhToanThanhCong';
import CashierHome from './components/staffPages/ThuNgan/ThuNganHome';
import GhiNhanThanhToan from './components/staffPages/ThuNgan/GhiNhanThanhToan';
import PhaCheHome from './components/staffPages/PhaChe/PhaCheHome';
import XuLyDoUong from './components/staffPages/PhaChe/XuLyDoUong';
import AdminHome from './components/staffPages/Admin/AdminHome';
import QuanLyNguoiDung from './components/staffPages/Admin/QuanLyNguoiDung';
import QuanLySanPham from './components/staffPages/Admin/QuanLySanPham';
import EditProductPage from './components/EditProductPage'; 
import BaoCao from './components/staffPages/Admin/BaoCao';
import KhuyenMai from './components/staffPages/Admin/KhuyenMai';
import AddKhuyenMai from './components/staffPages/Admin/AddKhuyenMai';
import QuanLyKho from './components/staffPages/PhaChe/QuanLyKho';
import PhieuNhapKho from './components/staffPages/PhaChe/PhieuNhapKho';
import PhieuXuatKho from './components/staffPages/PhaChe/PhieuXuatKho';
import SuaSanPham from './components/staffPages/Admin/SuaSanPham'; // Giả sử bạn đã tạo component SuaSanPham
import KhuyenMaiList from './components/staffPages/Admin/KhuyenMaiList'; // Import component mới




function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/order" element={<OrderPage />} />
          <Route path="/return" element={<ReturnPage />} />
          <Route path="/cashier" element={<CashierPage />} />
          <Route path="/cashier-home" element={<CashierHome />} />
          <Route path="/ghi-nhan-thanh-toan" element={<GhiNhanThanhToan />} />
          <Route path="/pha-che" element={<PhaCheHome />} />
          <Route path="/xu-ly-do-uong" element={<XuLyDoUong />} />
          <Route path="/admin-home" element={<AdminHome />} />
          <Route path="/quan-ly-nguoi-dung" element={<QuanLyNguoiDung />} />
          <Route path="/quan-ly-san-pham" element={<QuanLySanPham />} />
          <Route path="/edit-product/:id" element={<EditProductPage />} />
          <Route path="/phuc-vu" element={<PhucVuHome />} />
          <Route path="/ghi-order" element={<GhiOrder />} />
          <Route path="/order-thanh-cong" element={<OrderThanhCong />} />
          <Route path="/tra-mon" element={<TraMon />} />
          <Route path="/thong-bao-tra-mon" element={<ThongBaoTraMon />} />
          <Route path="/thanh-toan-thanh-cong" element={<ThanhToanThanhCong />} />
          <Route path="/bao-cao" element={<BaoCao />} />
          <Route path="/khuyen-mai" element={<KhuyenMai />} />
          <Route path="/add-khuyen-mai" element={<AddKhuyenMai />} />
          <Route path="/quanly-kho" element={<QuanLyKho />} />
          <Route path="/phieu-nhap-kho" element={<PhieuNhapKho />} />
          <Route path="/phieu-xuat-kho" element={<PhieuXuatKho />} />
          <Route path="/sua-san-pham/:id" element={<SuaSanPham />} />
          <Route path="/edit-promotion/:id" element={<KhuyenMaiList />} />


        </Routes>
      </div>
    </Router>
  );
}

export default App;
