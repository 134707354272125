import React from 'react';
import { useNavigate } from 'react-router-dom';

function OrderThanhCong() {
  const navigate = useNavigate();

  const handleNextStep = () => {
    navigate('/tra-mon');
  };

  return (
    <div className="order-thanh-cong">
      <h1>MH Order Thành Công</h1>
      <button onClick={handleNextStep}>Trả Món</button>
    </div>
  );
}

export default OrderThanhCong;
