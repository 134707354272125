import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import './QuanLyNguoiDung.css'; 

const QuanLyNguoiDung = () => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [newEmployee, setNewEmployee] = useState({
    id: '',
    username: '',
    name: '',
    birthday: '',
    gender: '',
    role: '',
  });

  const navigate = useNavigate();

  // Hàm gọi API để lấy dữ liệu nhân viên
  const fetchEmployees = async () => {
    try {
      const response = await fetch('http://localhost:5000/api/users');
      const data = await response.json();
      setEmployees(data);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEmployee((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Hàm định dạng ngày tháng thủ công
  const formatDate = (isoDate) => {
    if (!isoDate) return '';
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Hàm thêm nhân viên
  const handleAddEmployee = async () => {
    try {
      const response = await fetch('http://localhost:5000/api/users/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newEmployee),
      });

      if (response.ok) {
        await response.json();
        fetchEmployees();
        setShowAddForm(false);
        setNewEmployee({ id: '', username: '', name: '', birthday: '', gender: '', role: '' });
      } else {
        const errorData = await response.json();
        console.error('Error adding user:', errorData.message);
      }
    } catch (error) {
      console.error('Error adding employee:', error);
    }
  };

  // Hàm sửa nhân viên
  const handleEditEmployee = async () => {
    try {
      const response = await fetch(`http://localhost:5000/api/users/edit/${newEmployee._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newEmployee),
      });

      if (response.ok) {
        await response.json();
        fetchEmployees();
        setShowAddForm(false);
        setNewEmployee({ _id: '', id: '', username: '', name: '', birthday: '', gender: '', role: '' });
      } else {
        const errorData = await response.json();
        console.error('Error updating user:', errorData.message);
      }
    } catch (error) {
      console.error('Error updating employee:', error);
    }
  };

  // Hàm xóa nhân viên
  const handleDeleteEmployee = async (id) => {
    try {
      const response = await fetch(`http://localhost:5000/api/users/delete/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        fetchEmployees();
      } else {
        console.error('Error deleting employee');
      }
    } catch (error) {
      console.error('Error deleting employee:', error);
    }
  };

  const handleEditClick = (employee) => {
    setShowAddForm(true);
    setNewEmployee(employee);
  };

  const handleGoBack = () => {
    navigate('/admin-home');
  };

  return (
    <div className="employee-management-container">
      <header className="employee-header">
        <h1>Quản lý nhân viên</h1>
        <button className="add-button" onClick={() => setShowAddForm(true)}>Thêm nhân viên</button>
        <button className="close-button" onClick={handleGoBack}>Quay lại</button>
      </header>

      {showAddForm && (
        <div className="add-employee-form">
          <h2>{newEmployee._id ? 'Sửa Nhân viên' : 'Thêm Nhân viên mới'}</h2>
          <label>Mã nhân viên:</label>
          <input type="text" name="id" value={newEmployee.id} onChange={handleInputChange} required />
          <label>Tên nhân viên:</label>
          <input type="text" name="name" value={newEmployee.name} onChange={handleInputChange} required />
          <label>Tên đăng nhập:</label>
          <input type="text" name="username" value={newEmployee.username} onChange={handleInputChange} required />
          <label>Ngày sinh:</label>
          <input type="date" name="birthday" value={newEmployee.birthday} onChange={handleInputChange} required />
          <label>Giới tính:</label>
          <select name="gender" value={newEmployee.gender} onChange={handleInputChange} required>
            <option value="">Chọn giới tính</option>
            <option value="Nam">Nam</option>
            <option value="Nữ">Nữ</option>
          </select> 
          <label>Vai trò:</label>
          <input type="text" name="role" value={newEmployee.role} onChange={handleInputChange} required />
          <button onClick={newEmployee._id ? handleEditEmployee : handleAddEmployee}>Lưu</button>
          <button onClick={handleGoBack}>Hủy</button>
        </div>
      )}

      <table className="employee-table">
        <thead>
          <tr>
            <th>Mã nhân viên</th>
            <th>Tên đăng nhập</th>
            <th>Tên nhân viên</th>
            <th>Ngày sinh</th>
            <th>Giới tính</th>
            <th>Vai trò</th>
            <th>Hành động</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((employee, index) => (
            <tr key={index}>
              <td>{employee.id}</td>
              <td>{employee.username}</td>
              <td>{employee.name}</td>
              <td>{formatDate(employee.birthday)}</td> {/* Sử dụng formatDate */}
              <td>{employee.gender}</td>
              <td>{employee.role}</td>
              <td>
                <button onClick={() => handleEditClick(employee)}>Sửa</button>
                <button onClick={() => handleDeleteEmployee(employee._id)}>Xóa</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QuanLyNguoiDung;
