import React from 'react';
import './DiscountModal.css';

const DiscountModal = ({ discount, onClose, onApply }) => {
  if (!discount) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Chương trình khuyến mãi</h3>
        <p>{discount.name}</p>
        <p>Giảm giá: {discount.percent}%</p>
        <img src={discount.image} alt={discount.name} className="discount-img" />
        <button className="apply-btn" onClick={onApply}>Áp dụng</button>
        <button className="close-btn" onClick={onClose}>Đóng</button>
      </div>
    </div>
  );
};

export default DiscountModal;
