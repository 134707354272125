import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './KhuyenMai.css';

const KhuyenMai = () => {
  const navigate = useNavigate();
  const [promotions, setPromotions] = useState([]);
  const [editingPromotionId, setEditingPromotionId] = useState(null);
  const [editedPromotion, setEditedPromotion] = useState({
    promotionCode: '',
    promotionName: '',
    startDate: '',
    endDate: '',
    promotionValue: ''
  });

  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const response = await axios.get('http://localhost:5000/api/promotions');
        setPromotions(response.data);
      } catch (error) {
        console.error("Lỗi khi lấy danh sách khuyến mãi:", error);
      }
    };

    fetchPromotions();
  }, []);

  const handleDelete = async (id) => {
    const isConfirmed = window.confirm("Bạn có chắc chắn muốn xóa khuyến mãi này không?");
    if (isConfirmed) {
      try {
        await axios.delete(`http://localhost:5000/api/promotions/${id}`);
        setPromotions(promotions.filter((promotion) => promotion._id !== id));
      } catch (error) {
        console.error("Lỗi khi xóa khuyến mãi:", error);
      }
    }
  };

  const handleEdit = (promotion) => {
    setEditingPromotionId(promotion._id);
    setEditedPromotion(promotion);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedPromotion((prevPromotion) => ({
      ...prevPromotion,
      [name]: value,
    }));
  };

  const handleSave = async (id) => {
    try {
      await axios.put(`http://localhost:5000/api/promotions/${id}`, editedPromotion);
      setPromotions(promotions.map((promotion) =>
        promotion._id === id ? editedPromotion : promotion
      ));
      setEditingPromotionId(null);
    } catch (error) {
      console.error("Lỗi khi cập nhật khuyến mãi:", error);
    }
  };

  return (
    <div className="khuyen-mai-container">
      <header className="khuyen-mai-header">
        <h2>Chương trình Khuyến mãi</h2>
        <button className="btn-back" onClick={() => navigate('/admin-home')}>Quay lại</button>
      </header>
      
      <main className="khuyen-mai-content">
        <div className="promotion-actions">
          <button className="btn-add" onClick={() => navigate('/add-khuyen-mai')}>Thêm Khuyến mãi</button>
        </div>

        <table className="promotion-table">
          <thead>
            <tr>
              <th>Mã Khuyến mãi</th>
              <th>Tên Khuyến mãi</th>
              <th>Ngày bắt đầu</th>
              <th>Ngày kết thúc</th>
              <th>Giá trị Khuyến mãi</th>
              <th>Hành động</th>
            </tr>
          </thead>
          <tbody>
            {promotions.map((promotion) => (
              <tr key={promotion._id}>
                {editingPromotionId === promotion._id ? (
                  <>
                    <td><input type="text" name="promotionCode" value={editedPromotion.promotionCode} onChange={handleInputChange} /></td>
                    <td><input type="text" name="promotionName" value={editedPromotion.promotionName} onChange={handleInputChange} /></td>
                    <td><input type="date" name="startDate" value={editedPromotion.startDate} onChange={handleInputChange} /></td>
                    <td><input type="date" name="endDate" value={editedPromotion.endDate} onChange={handleInputChange} /></td>
                    <td><input type="number" name="promotionValue" value={editedPromotion.promotionValue} onChange={handleInputChange} /></td>
                    <td>
                      <button className="btn-save" onClick={() => handleSave(promotion._id)}>Lưu</button>
                      <button className="btn-cancel" onClick={() => setEditingPromotionId(null)}>Hủy</button>
                    </td>
                  </>
                ) : (
                  <>
                    <td>{promotion.promotionCode}</td>
                    <td>{promotion.promotionName}</td>
                    <td>{promotion.startDate}</td>
                    <td>{promotion.endDate}</td>
                    <td>{promotion.promotionValue}%</td>
                    <td>
                      <button className="btn-edit" onClick={() => handleEdit(promotion)}>Sửa</button>
                      <button className="btn-delete" onClick={() => handleDelete(promotion._id)}>Xóa</button>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </main>
    </div>
  );
};

export default KhuyenMai;
