import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './QuanLyKho.css';

const QuanLyKho = () => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleBackToPhaCheHome = () => {
    navigate('/pha-che');  // Điều hướng về trang PhaCheHome
  };

  const handleOptionSelect = (option) => {
    if (option === 'nhap') {
      navigate('/phieu-nhap-kho');  // Điều hướng sang trang Phiếu nhập kho
    } else if (option === 'xuat') {
      navigate('/phieu-xuat-kho');  // Điều hướng sang trang Phiếu xuất kho
    }
  };

  return (
    <div className="quanlykho-container">
      <header className="header">
        <div className="header-left">
          <i className="fas fa-bars"></i>
          <span>DS món chế biến</span>
        </div>
        <div className="header-center">
          <span>Quản lý Kho</span>
        </div>
        <div className="header-right">
          <i className="fas fa-wifi"></i>
          <i className="fas fa-question-circle"></i>
          <span>Võ Minh Tuấn</span>
          <i className="fas fa-user-circle"></i>
        </div>
      </header>

      <div className="action-buttons">
        {/* Nút Thêm với menu chọn */}
        <div className="dropdown">
          <button className="btn" onClick={() => setShowDropdown(!showDropdown)}>Thêm</button>
          {showDropdown && (
            <div className="dropdown-content">
              <button onClick={() => handleOptionSelect('nhap')}>Phiếu nhập kho</button>
              <button onClick={() => handleOptionSelect('xuat')}>Phiếu xuất kho</button>
            </div>
          )}
        </div>
        <button className="btn">Sửa</button>
        <button className="btn">Xóa</button>
        <button className="btn-large">Thống kê NVL tồn kho</button>
      </div>

      {/* Button quay về trang PhaCheHome */}
      <button onClick={handleBackToPhaCheHome} className="back-button">
        Quay lại
      </button>
    </div>
  );
};

export default QuanLyKho;
