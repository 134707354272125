import React from 'react';
import './PhieuNhapKho.css';  // Tạo file CSS riêng cho trang này

const PhieuNhapKho = () => {
  return (
    <div className="phieu-nhap-kho">
      <h2>Thêm phiếu nhập kho</h2>
      <form>
        <label>Mã PN</label>
        <input type="text" value="PN0014" readOnly />
        <label>Người lập phiếu</label>
        <input type="text" value="Võ Minh Tuấn" readOnly />
        <label>Ngày lập phiếu</label>
        <input type="date" value="2021-11-30" />
        <label>Người giao</label>
        <input type="text" value="Anh Đạt" />
        <label>Nhà cung cấp</label>
        <input type="text" value="Dasfoods 247 Phan Bội Châu" />
        <table>
          <thead>
            <tr>
              <th>Mã NVL</th>
              <th>Tên NVL</th>
              <th>Đơn vị tính</th>
              <th>Số lượng</th>
              <th>HSD</th>
              <th>Đơn giá</th>
              <th>Thành tiền</th>
            </tr>
          </thead>
          <tbody>
            {/* Các dòng dữ liệu */}
          </tbody>
        </table>
        <button type="button">Thêm dòng</button>
        <button type="button">Xóa dòng</button>
        <div className="form-footer">
          <button type="submit">Lưu</button>
          <button type="button">Hủy bỏ</button>
          <button type="button">Giúp</button>
        </div>
      </form>
    </div>
  );
};

export default PhieuNhapKho;
