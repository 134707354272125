import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import axios from 'axios'; // Import axios để gửi request
import './QuanLySanPham.css';

function QuanLySanPham() {
  const navigate = useNavigate();

  // State for table rows (dynamically adding/removing/editing)
  const [rows, setRows] = useState([
    { id: 1, ma: 'HOADUBIEC', ten: 'Hoa Đậu Biếc', soLuong: '10', donVi: 'gram' },
    { id: 2, ma: 'WHIPPING', ten: 'Whipping Cream', soLuong: '20', donVi: 'ml' },
    { id: 3, ma: 'SUGARFOOD', ten: 'Sữa đặc không đường', soLuong: '15', donVi: 'ml' },
    { id: 4, ma: 'SUGAR', ten: 'Đường', soLuong: '20', donVi: 'gram' },
    { id: 5, ma: 'TOPCREAM', ten: 'Topping Cream', soLuong: '10', donVi: 'ml' }
  ]);

  // Function to handle adding a new row
  const handleAddRow = () => {
    const newRow = { id: Date.now(), ma: '', ten: '', soLuong: '', donVi: '' };
    setRows([...rows, newRow]);
  };

  // Function to handle deleting a row
  const handleDeleteRow = (id) => {
    setRows(rows.filter(row => row.id !== id));
  };

  // Function to handle editing row values
  const handleEditRow = (id, field, value) => {
    setRows(rows.map(row => row.id === id ? { ...row, [field]: value } : row));
  };

  // Image upload state and functions
  const [image, setImage] = useState(null);
  
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file)); // Preview the uploaded image
    }
  };

  const handleImageDelete = () => {
    setImage(null); // Remove image
  };

  // State for form input data
  const [productData, setProductData] = useState({
    ten: 'Trà hoa Đậu Biếc Macchiato',
    ma: 'U06',
    gia: '',
    loai: 'Đồ uống',
    nhomThucDon: 'Trà',
    cheBienTai: 'Quầy bar',
    donViTinh: 'Ly',
  });

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData({ ...productData, [name]: value });
  };

  // Handle form submission
  const handleSaveProduct = async (e) => {
    e.preventDefault();

    // Kiểm tra dữ liệu cho các nguyên liệu
    const isValidRow = rows.every(row => row.ma && row.ten && row.soLuong && row.donVi);
    if (!isValidRow) {
        alert('Vui lòng điền đầy đủ thông tin cho tất cả các nguyên liệu.');
        return;
    }

    // Kiểm tra xem các trường cần thiết có giá trị hợp lệ không
    if (!productData.ma || !productData.gia || !productData.loai) {
        alert('Vui lòng điền đầy đủ thông tin cho sản phẩm.');
        return;
    }

    const formData = new FormData();
    formData.append('name', productData.ten);
    formData.append('code', productData.ma);
    formData.append('price', parseFloat(productData.gia.replace(/[^0-9.-]+/g,"")));
    formData.append('category', productData.loai);
    formData.append('stock', parseInt(rows.reduce((total, row) => total + parseInt(row.soLuong || 0), 0)));
    formData.append('donViTinh', productData.donViTinh);
    formData.append('nhomThucDon', productData.nhomThucDon); // Thêm dòng này để gửi `nhomThucDon`

    // Lặp qua các nguyên liệu và thêm vào formData
    rows.forEach((row, index) => {
        formData.append(`ingredients[${index}][name]`, row.ten);
        formData.append(`ingredients[${index}][quantity]`, row.soLuong);
        formData.append(`ingredients[${index}][unit]`, row.donVi);
    });

    if (image) {
        const fileInput = document.querySelector('input[type="file"]');
        formData.append('image', fileInput.files[0]);
    }

    try {
        const response = await axios.post('http://localhost:5000/api/products', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        console.log(response.data);
        navigate('/admin-home');
    } catch (error) {
        console.error('Lỗi khi lưu sản phẩm:', error.response ? error.response.data : error.message);
        if (error.response) {
            console.log('Dữ liệu lỗi:', error.response.data);
            console.log('Mã lỗi:', error.response.status);
        } else {
            console.log('Lỗi mạng hoặc không thể kết nối:', error.message);
        }
    }
};


  const handleBackToHomeClick = () => {
    navigate('/admin-home'); // Navigate back to home
  };

  return (
    <div className="qlsp-container">
      <div className="qlsp-header">
        <h2>Thêm món</h2>
      </div>

      <div className="qlsp-content">
        <form onSubmit={handleSaveProduct}> {/* Form submit */}
          <div className="qlsp-form-section">
            {/* Loại món */}
            <label>Loại</label>
            <div className="radio-group">
              <input type="radio" id="do-uong" name="loai" checked={productData.loai === 'Đồ uống'} onChange={handleInputChange} value="Đồ uống" />
              <label htmlFor="do-uong">Đồ uống</label>
              <input type="radio" id="mon-an" name="loai" checked={productData.loai === 'Món ăn'} onChange={handleInputChange} value="Món ăn" />
              <label htmlFor="mon-an">Món ăn</label>
            </div>

            {/* Tên món */}
            <label>Tên món <span className="qlsp-required">*</span></label>
            <input type="text" name="ten" className="qlsp-input-field" value={productData.ten} onChange={handleInputChange} required />

            {/* Mã món */}
            <label>Mã món <span className="qlsp-required">*</span></label>
            <input type="text" name="ma" className="qlsp-input-field" value={productData.ma} onChange={handleInputChange} required />

            {/* Giá bán */}
            <label>Giá bán <span className="qlsp-required">*</span></label>
            <input type="text" name="gia" className="qlsp-input-field" value={productData.gia} onChange={handleInputChange} required />

            {/* Nhóm thực đơn */}
            <label>Nhóm thực đơn</label>
            <div className="qlsp-dropdown-group">
              <input type="text" name="nhomThucDon" className="qlsp-input-field" value={productData.nhomThucDon} onChange={handleInputChange} />
            </div>

            {/* Chế biến tại */}
            <label>Chế biến tại</label>
            <div className="qlsp-dropdown-group">
              <input type="text" name="cheBienTai" className="qlsp-input-field" value={productData.cheBienTai} onChange={handleInputChange} />
            </div>

            {/* Đơn vị tính */}
            <label>Đơn vị tính <span className="qlsp-required">*</span></label>
            <div className="qlsp-dropdown-group">
              <input type="text" name="donViTinh" className="qlsp-input-field" value={productData.donViTinh} onChange={handleInputChange} required />
            </div>
          </div>

          {/* Image Upload Section */}
          <div className="qlsp-image-section">
            <label>Ảnh đại diện</label>
            <div className="qlsp-image-container">
              {image ? (
                <img src={image} alt="Đồ uống" className="qlsp-image-preview" />
              ) : (
                <p>Chưa có ảnh</p>
              )}
              <div className="qlsp-image-buttons">
                <input type="file" onChange={handleImageUpload} />
                <button type="button" className="qlsp-delete-button" onClick={handleImageDelete}>
                  <i className="fas fa-times"></i> Xóa
                </button>
              </div>
            </div>
          </div>

          {/* Ingredients Section */}
          <div className="qlsp-ingredients-section">
            <label>Nguyên liệu</label>
            {rows.map((row) => (
              <div key={row.id} className="qlsp-ingredient-row">
                <input type="text" value={row.ma} onChange={(e) => handleEditRow(row.id, 'ma', e.target.value)} placeholder="Mã nguyên liệu" />
                <input type="text" value={row.ten} onChange={(e) => handleEditRow(row.id, 'ten', e.target.value)} placeholder="Tên nguyên liệu" />
                <input type="text" value={row.soLuong} onChange={(e) => handleEditRow(row.id, 'soLuong', e.target.value)} placeholder="Số lượng" />
                <input type="text" value={row.donVi} onChange={(e) => handleEditRow(row.id, 'donVi', e.target.value)} placeholder="Đơn vị" />
                <button type="button" className="qlsp-delete-button" onClick={() => handleDeleteRow(row.id)}>
                  <i className="fas fa-trash"></i> Xóa
                </button>
              </div>
            ))}
            <button type="button" onClick={handleAddRow} className="qlsp-add-button">Thêm nguyên liệu</button>
          </div>

          <div className="qlsp-buttons">
            <button type="submit" className="qlsp-save-button">Lưu</button>
            <button type="button" className="qlsp-cancel-button" onClick={handleBackToHomeClick}>Quay lại</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default QuanLySanPham;
