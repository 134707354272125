import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './GhiOrder.css';
import DiscountModal from './DiscountModal';

const GhiOrder = () => {
  const [selectedCategory, setSelectedCategory] = useState('Cafe');
  const [products, setProducts] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [discounts, setDiscounts] = useState([]);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [selectedTable, setSelectedTable] = useState('1.1 - Bàn 001/Tầng 1');
  const navigate = useNavigate();

  const fetchProducts = async (category) => {
    try {
      const params = category === 'Khác'
        ? { nhomThucDon: { $nin: ['Cafe', 'Trà', 'Bánh'] } }
        : { nhomThucDon: category };

      const response = await axios.get(`http://localhost:5000/api/products`, { params });
      setProducts(response.data);
    } catch (error) {
      console.error('Lỗi khi tải sản phẩm:', error);
    }
  };

  const fetchDiscounts = async () => {
    try {
      const response = await axios.get(`http://localhost:5000/api/promotions`);
      setDiscounts(response.data);
    } catch (error) {
      console.error('Lỗi khi tải ưu đãi:', error);
    }
  };

  useEffect(() => {
    fetchProducts(selectedCategory);
    fetchDiscounts();
  }, [selectedCategory]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSearchQuery('');
  };

  const handleAddToOrder = (product) => {
    setOrderItems((prevItems) => {
      const existingItem = prevItems.find((item) => item.name === product.name);
      if (existingItem) {
        return prevItems.map((item) =>
          item.name === product.name
            ? { ...item, quantity: item.quantity + 1, total: (item.total + product.price) }
            : item
        );
      }
      return [...prevItems, { ...product, quantity: 1, total: product.price }];
    });
  };

  const handleRemoveItem = (name) => {
    setOrderItems((prevItems) =>
      prevItems.filter((item) => item.name !== name)
    );
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(`http://localhost:5000/api/products/search`, {
        params: { query: searchQuery }
      });
      setProducts(response.data);
    } catch (error) {
      console.error('Lỗi khi tìm kiếm sản phẩm:', error);
    }
  };

  const handleClearOrder = () => {
    setOrderItems([]);
    setSelectedDiscount(null);
  };

  const calculateTotal = () => {
    const total = orderItems.reduce((acc, item) => acc + (item.total || 0), 0);
    if (selectedDiscount) {
      const discountValue = parseFloat(selectedDiscount.promotionValue) || 0;
      const discountAmount = total * (discountValue / 100);
      return total - discountAmount;
    }
    return total;
  };

  const handleDiscountSelect = (discountId) => {
    const discount = discounts.find(d => d._id === discountId);
    setSelectedDiscount(discount || null);
  };

  const handleApplyDiscount = () => {
    setShowDiscountModal(false);
  };

  const handleSendOrder = () => {
    const orderData = {
      table: selectedTable,
      items: orderItems,
      total: calculateTotal()
    };
    navigate('/phuc-vu', { state: { newOrder: orderData } });
  };

  return (
    <div className="grid-container">
      <div className="left">
        <div className="menu-buttons">
          <button
            className={`button ${selectedCategory === 'Cafe' ? 'active' : ''}`}
            onClick={() => handleCategoryChange('Cafe')}
          >
            Cafe
          </button>
          <button
            className={`button ${selectedCategory === 'Trà' ? 'active' : ''}`}
            onClick={() => handleCategoryChange('Trà')}
          >
            Trà
          </button>
          <button
            className={`button ${selectedCategory === 'Bánh' ? 'active' : ''}`}
            onClick={() => handleCategoryChange('Bánh')}
          >
            Bánh
          </button>
          <button
            className={`button ${selectedCategory === 'Khác' ? 'active' : ''}`}
            onClick={() => handleCategoryChange('Khác')}
          >
            Khác
          </button>
        </div>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Nhập mã/Tên món cần tìm"
            className="input"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button className="button" onClick={handleSearch}>Tìm Kiếm</button>
        </div>
        <div className="products">
          {products.map((product) => (
            <Product key={product._id} product={product} onAddToOrder={handleAddToOrder} />
          ))}
        </div>
      </div>
      <div className="right">
        <div className="table-selection">
          <label>Chọn bàn:</label>
          <select onChange={(e) => setSelectedTable(e.target.value)} value={selectedTable} className="input">
            <optgroup label="Tầng 1">
              <option value="1.1 - Bàn 001/Tầng 1">1.1 - Bàn 001/Tầng 1</option>
              <option value="1.2 - Bàn 002/Tầng 1">1.2 - Bàn 002/Tầng 1</option>
              <option value="1.3 - Bàn 003/Tầng 1">1.3 - Bàn 003/Tầng 1</option>
              <option value="1.4 - Bàn 004/Tầng 1">1.4 - Bàn 004/Tầng 1</option>
              <option value="1.5 - Bàn 005/Tầng 1">1.5 - Bàn 005/Tầng 1</option>
            </optgroup>
            <optgroup label="Tầng 2">
              <option value="2.1 - Bàn 001/Tầng 2">2.1 - Bàn 001/Tầng 2</option>
              <option value="2.2 - Bàn 002/Tầng 2">2.2 - Bàn 002/Tầng 2</option>
              <option value="2.3 - Bàn 003/Tầng 2">2.3 - Bàn 003/Tầng 2</option>
              <option value="2.4 - Bàn 004/Tầng 2">2.4 - Bàn 004/Tầng 2</option>
              <option value="2.5 - Bàn 005/Tầng 2">2.5 - Bàn 005/Tầng 2</option>
            </optgroup>
          </select>
        </div>
        <table className="order-table">
          <thead>
            <tr>
              <th>Tên món</th>
              <th>Số lượng</th>
              <th>Thành tiền</th>
              <th>Xóa</th>
            </tr>
          </thead>
          <tbody>
            {orderItems.map((item, index) => (
              <OrderItem key={index} item={item} onRemove={handleRemoveItem} />
            ))}
          </tbody>
        </table>
        <div className="discount-section">
          <label>Chọn ưu đãi:</label>
          <select onChange={(e) => handleDiscountSelect(e.target.value)} value={selectedDiscount?._id || ''}>
            <option value="">Không áp dụng</option>
            {discounts.map((discount) => (
              <option key={discount._id} value={discount._id}>
                {discount.promotionName} - Giảm {discount.promotionValue}%
              </option>
            ))}
          </select>
          <button onClick={() => setShowDiscountModal(true)} className="button">Chi tiết ưu đãi</button>
        </div>

        {showDiscountModal && (
          <DiscountModal
            discount={selectedDiscount}
            onClose={() => setShowDiscountModal(false)}
            onApply={handleApplyDiscount}
          />
        )}

        <div className="total">
          <span>Tổng tiền</span>
          <span>{calculateTotal().toLocaleString('vi-VN')} VND</span>
        </div>
        <div className="actions">
          <button className="button" onClick={handleClearOrder}>Hủy tất cả món đã chọn</button>
          <button className="button" onClick={handleSendOrder}>Gửi</button>
          <button className="button cancel">Hủy bỏ</button>
        </div>
      </div>
    </div>
  );
};

const Product = ({ product, onAddToOrder }) => (
  <div className="product" onClick={() => onAddToOrder(product)}>
    <img src={product.image} alt={product.name} className="product-img" />
    <p>{product.name}</p>
    <p>{product.price.toLocaleString('vi-VN')} VND</p>
  </div>
);

const OrderItem = ({ item, onRemove }) => (
  <tr>
    <td>{item.name}</td>
    <td>{item.quantity}</td>
    <td>{item.total.toLocaleString('vi-VN')} VND</td>
    <td>
      <button onClick={() => onRemove(item.name)} className="remove-btn">X</button>
    </td>
  </tr>
);

export default GhiOrder;
