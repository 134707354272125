import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

function EditProductPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({ name: '', price: '', category: '' });

  // Lấy dữ liệu sản phẩm hiện tại
  useEffect(() => {
    const loadProduct = async () => {
      const result = await axios.get(`http://localhost:5000/api/products/${id}`);
      setProduct(result.data);
    };
    loadProduct();
  }, [id]);

  // Cập nhật sản phẩm
  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios.put(`http://localhost:5000/api/products/${id}`, product);
    navigate('/products'); // Điều hướng về trang danh sách sản phẩm
  };

  return (
    <div>
      <h1>Sửa sản phẩm</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Tên sản phẩm:</label>
          <input type="text" value={product.name} onChange={(e) => setProduct({ ...product, name: e.target.value })} required />
        </div>
        <div>
          <label>Giá:</label>
          <input type="number" value={product.price} onChange={(e) => setProduct({ ...product, price: e.target.value })} required />
        </div>
        <div>
          <label>Loại sản phẩm:</label>
          <input type="text" value={product.category} onChange={(e) => setProduct({ ...product, category: e.target.value })} required />
        </div>
        <button type="submit">Cập nhật sản phẩm</button>
      </form>
    </div>
  );
}

export default EditProductPage;
