// ReturnPage.js
import React from 'react';

const ReturnPage = () => {
  return (
    <div>
      <h1>Trả món</h1>
      <div>
        <h2>Bàn đang phục vụ</h2>
        <select>
          <option>Bàn 1</option>
          <option>Bàn 2</option>
          {/* Các bàn khác */}
        </select>
      </div>
      <div>
        <h3>Món đã gọi</h3>
        {/* Hiển thị danh sách món đã gọi, cho phép sửa/xóa/thêm */}
      </div>
      <button>Xác nhận</button>
    </div>
  );
};

export default ReturnPage;
