import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'tailwindcss/tailwind.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWifi, faQuestionCircle, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import '../../../tailwind.css';
import './PhucVuHome.css';

const PhucVuHome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [orders, setOrders] = useState([]);
  const [tables, setTables] = useState([
    { table: '1.1 - Bàn 001/Tầng 1', hasOrder: false },
    { table: '1.2 - Bàn 002/Tầng 1', hasOrder: false },
    { table: '1.3 - Bàn 003/Tầng 1', hasOrder: false },
    { table: '1.4 - Bàn 004/Tầng 1', hasOrder: false },
    { table: '1.5 - Bàn 005/Tầng 1', hasOrder: false },
    { table: '2.1 - Bàn 001/Tầng 2', hasOrder: false },
    { table: '2.2 - Bàn 002/Tầng 2', hasOrder: false },
    { table: '2.3 - Bàn 003/Tầng 2', hasOrder: false },
    { table: '2.4 - Bàn 004/Tầng 2', hasOrder: false },
    { table: '2.5 - Bàn 005/Tầng 2', hasOrder: false },
  ]);

  useEffect(() => {
    // Kiểm tra nếu có đơn hàng mới từ GhiOrder truyền sang
    if (location.state?.newOrder) {
      setOrders((prevOrders) => [location.state.newOrder, ...prevOrders]);
      setTables((prevTables) =>
        prevTables.map((table) =>
          table.table === location.state.newOrder.table
            ? { ...table, hasOrder: true }
            : table
        )
      );
    }
  }, [location.state]);

  // Xử lý chuyển hướng khi nhấn nút Trả món
  const handleTraMonClick = () => {
    navigate('/tra-mon');
  };

  // Xử lý chuyển hướng khi nhấn nút Logout
  const handleLogoutClick = () => {
    navigate('/');
  };

  // Xử lý chuyển hướng khi nhấn nút '+'
  const handleAddButtonClick = () => {
    navigate('/ghi-order');
  };

  return (
    <div className="container">
      <header className="header">
        <div className="header-left">
          <i className="fas fa-bars icon"></i>
          <div className="menu-item">
            <i className="fas fa-clipboard-list icon"></i>
            <span>Order</span>
          </div>
          <div className="menu-item">
            <i className="fas fa-utensils icon"></i>
            <button className="tab" onClick={handleTraMonClick}>Trả món</button>
          </div>
        </div>
        <div className="header-right">
          <FontAwesomeIcon icon={faWifi} className="text-xl" />
          <FontAwesomeIcon icon={faQuestionCircle} className="text-xl" />
          <button onClick={handleLogoutClick} className="icon-button">
            <FontAwesomeIcon icon={faPowerOff} className="text-xl" />
          </button>
          <span>Chú Bé Bi</span>
          <i className="fas fa-user-circle icon"></i>
        </div>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"></link>
      </header>

      <div className="tabs">
        <button className="tab active">Đang phục vụ ({orders.length})</button>
        <button className="tab">Yêu cầu thanh toán (0)</button>
        <button className="tab">Mang về (1)</button>
      </div>

      <div className="table-list" style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '10px' }}>
        {tables
          .filter((table, index, self) => index === self.findIndex(t => t.table === table.table))
          .sort((a, b) => (a.hasOrder === b.hasOrder ? 0 : a.hasOrder ? -1 : 1))
          .map((table, index) => (
            <div
              key={index}
              className={`table-card ${table.hasOrder ? 'table-active' : 'table-inactive'}`}
              onClick={() => navigate('/ghi-order', { state: { selectedTable: table.table } })}
              style={{
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                height: '100px',
                backgroundColor: table.hasOrder ? '#fdd835' : '#f0f0f0',
              }}
            >
              <span>{table.table}</span>
            </div>
          ))}
      </div>

      <div className="content">
        {orders.map((order, index) => (
          <div className="card" key={index} style={{ maxWidth: '400px', margin: '0 auto 20px auto' }}>
            <div className="card-header">
              <span>{order.table}</span>
              <i className="fas fa-users"></i>
            </div>
            <div className="card-body">
              <div className="time">
                <i className="fas fa-clock"></i>
                <span>{order.time || 'N/A'}</span>
              </div>
              <span>{order.total.toLocaleString('vi-VN')} VND</span>
            </div>
            <div className="card-footer">
              <i className="fas fa-bell"></i>
              <i className="fas fa-calendar-alt"></i>
              <i className="fas fa-th-large"></i>
              <i className="fas fa-ellipsis-h"></i>
            </div>
          </div>
        ))}
      </div>

      <button className="add-button" onClick={handleAddButtonClick}>
        <i className="fas fa-plus"></i>
      </button>
    </div>
  );
};

export default PhucVuHome;
